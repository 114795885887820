import React, { useState, useMemo, useCallback } from "react";

import { useVersion } from "../../hooks/useVersion";
import { ButtonGroup } from "@caesars-digital/caesars-ui";
import LatestDownloadBar from "../LatestDownloadBar/LatestDownloadBar";

import masterChangelog from "changelog";

function sortByDate(a, b) {
  return new Date(b["Release_Date"]) - new Date(a["Release_Date"]);
}

const MENU_TYPES = {
  all: "All",
  web: "Web (CUI)",
  ios: "iOS (SwiftCUI)",
};

const platforms = [
  { itemId: 0, label: MENU_TYPES.all },
  { itemId: 1, label: MENU_TYPES.web },
  { itemId: 2, label: MENU_TYPES.ios },
];

const newTag = `<span class="inline-flex items-center justify-center body-sm-bold text-fg-inverse bg-bg-primary py-1px px-2px rounded-2px mr-2 -translate-y-1">NEW</span>`;
const bugfixTag = `<span class="inline-flex items-center justify-center body-sm-bold text-fg-inverse bg-status-negative py-1px px-2px rounded-2px mr-2 -translate-y-1">BUGFIX</span>`;
const emailTag = (email) =>
  `<span class="text-fg-moderate body-md">@${email}</span>`;

function replaceText(note) {
  return note
    .replace("NEW:", newTag)
    .replace(/BUG ?FIX(ES)?:/i, bugfixTag)
    .replace(/(?:`|')(.*?)(?:`|')/g, "<code>$1</code>")
    .replace(/\(@(.+)\)/g, emailTag("$1"));
}

function parseNotes(notes) {
  const parsedNotes = notes.trim()
    .split("\n")
    .map((note) => `<li>${replaceText(note)}</li>`)
    .join("");
  return { __html: `<ul>${parsedNotes}</ul>` };
}

const WhatsNew = () => {
  const { allAirtableVersion, allAirtableSwiftCuiVersion } = useVersion();

  const [activeItem, setActiveItem] = useState(0);
  const handleClick = useCallback((e, id) => {
    setActiveItem(id);
  }, []);

  const versionsMap = useMemo(() => {
    const swiftCuiVersions = allAirtableSwiftCuiVersion.nodes
      .map(({ data }) => ({ ...data, type: MENU_TYPES.ios }))
      .sort(sortByDate);
    const versions = allAirtableVersion.nodes
      .map(({ data }, i) => ({
        ...data,
        type: MENU_TYPES.web,
        isLatest: i === 0,
      }))
      .sort(sortByDate);
    const allVersions = [...versions, ...swiftCuiVersions].sort(sortByDate);
    return {
      [MENU_TYPES.all]: allVersions,
      [MENU_TYPES.web]: versions,
      [MENU_TYPES.ios]: swiftCuiVersions,
    };
  }, [allAirtableSwiftCuiVersion.nodes, allAirtableVersion.nodes]);

  return (
    <>
      <ButtonGroup
        items={platforms}
        initialItem={activeItem}
        handleClick={handleClick}
        className="my-5"
      />
      {versionsMap[platforms[activeItem].label].map((version, i) => {
        if (!version.Notes) return null;
        const [, month, date, year] = new Date(version?.Release_Date)
          .toDateString()
          .split(" ") || ["", "", "", ""];
        let changelogs = [];
        if (version.Version in masterChangelog.byVersion) {
          changelogs = Object.entries(
            masterChangelog.byVersion[version.Version]
          );
        }

        return (
          <React.Fragment key={`${version.Version || "changelog-version"}-${i}`}>
            <h2>
              {version.type === MENU_TYPES.ios ? (
                <>
                  <img
                    src="/images/swift-icon.png"
                    className="inline-block w-8 h-8 mr-2"
                    alt="Swift Icon"
                  />
                  <span className="mr-2">SwiftCUI</span> 
                </>
              ) : (
                <>
                  <img
                    src="/images/caesars-ui-logo.png"
                    className="inline-block w-8 h-8 mr-2"
                    alt="Caesars UI Logo"
                  />
                  <span className="mr-2">Web</span> 
                </>
              )}
              <span className="mr-2">Update</span> 
              <span className="bg-highlight-accent-minimal text-highlight-accent">
                {version.Version}
              </span>
            </h2>
            <h5>
              {month} {date}, {year}
            </h5>
            {/* {version.type === MENU_TYPES.web && version.isLatest ? (
              <LatestDownloadBar />
            ) : null} */}
            <p dangerouslySetInnerHTML={parseNotes(version.Notes)}></p>
            {changelogs.length ? (
              <details>
                <summary className="heading-lg-bold text-fg-moderate cursor-pointer">
                  Changelog
                </summary>
                {changelogs.map(([componentName, logArray], i) => {
                  return (
                    <React.Fragment key={`${componentName}-changelog-${i}`}>
                      <h5 className="m-0 p-0 heading-md">{componentName}</h5>
                      <ul>
                        {logArray.map((log, i) => (
                          <li className="body-md" key={`${componentName}-log-item-${i}`}>{log}</li>
                        ))}
                      </ul>
                    </React.Fragment>
                  );
                })}
              </details>
            ) : null}
            <hr />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default WhatsNew;
