import cx from "components/src/utils/cx";
import { SizeType } from "../types/";

export const sizeArray = ["2xs", "xs", "sm", "md", "lg", "xl", "2xl", "3xl", "4xl", "5xl"];

export const gapMap = {
  "2xs": "cui-gap-2xs",
  "xs": "cui-gap-xs",
  "sm": "cui-gap-sm",
  "md": "cui-gap-md",
  "lg": "cui-gap-lg",
  "xl": "cui-gap-xl",
  "2xl": "cui-gap-2xl",
  "3xl": "cui-gap-3xl"
}

const sizeMap = new Map();
sizeMap.set("2xs", {width: 8, height: 8});
sizeMap.set("xs", {width: 10, height: 10});
sizeMap.set("sm", {width: 12, height: 12});
sizeMap.set("md", {width: 16, height: 16});
sizeMap.set("lg", {width: 18, height: 18});
sizeMap.set("xl", {width: 24, height: 24});
sizeMap.set("2xl", {width: 36, height: 36});
sizeMap.set("3xl", {width: 48, height: 48});
sizeMap.set("4xl", {width: 64, height: 64});
sizeMap.set("5xl", {width: 72, height: 72});

export const sizeStyles = (size: SizeType) => {
  const hasSize =
    typeof size === "string" &&
    sizeArray.includes(size);

  return {
    sizeClasses: typeof size !== 'number' ? cx(
      {
        "cui-w-2 cui-h-2": size === "2xs",
        "cui-w-[10px] cui-h-[10px]": size === "xs",
        "cui-w-3 cui-h-3": size === "sm",
        "cui-w-4 cui-h-4": size === "md" || !hasSize,
        "cui-w-[18px] cui-h-[18px]": size === "lg",
        "cui-w-6 cui-h-6": size === "xl",
        "cui-w-9 cui-h-9": size === "2xl",
        "cui-w-12 cui-h-12": size === "3xl",
        "cui-w-16 cui-h-16": size === "4xl",
        "cui-w-18 cui-h-18": size === "5xl",
        
      },
      "cui-relative cui-inline-block cui-p-0 cui-m-0 [&>svg]:cui-inline-block cui-box-border !cui-not-italic !cui-text-[0px] !cui-leading-[0px]"
    ) : 'cui-inline-block cui-box-border !cui-not-italic !cui-text-[0px] !cui-leading-[0px]',
    sizeStyle:
      !hasSize && typeof size === "number"
        ? {
            width: `${size}px`,
            height: `${size}px`,
          }
        : {},
    sizeMap: sizeMap.get(size) || { width: size }
  };
};
