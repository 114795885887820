/**
 * Do not edit directly
 * Generated on Mon, 09 Dec 2024 14:36:30 GMT
 */

module.exports = {
  "wh-light": {
    "fg": {
      "default": {
        "default": {
          "value": "#232424",
          "type": "color",
          "description": "Default color for text, icons and borders.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.silver.1100}",
            "type": "color",
            "description": "Default color for text, icons and borders.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-default",
          "path": [
            "wh-light",
            "fg",
            "default",
            "default"
          ]
        },
        "moderate": {
          "value": "#555a58",
          "type": "color",
          "description": "Content that is secondary or that provides additional context.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.silver.800}",
            "type": "color",
            "description": "Content that is secondary or that provides additional context.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-moderate",
          "path": [
            "wh-light",
            "fg",
            "default",
            "moderate"
          ]
        },
        "subtle": {
          "value": "#868c8a",
          "type": "color",
          "description": "Dividers or other elements not critical to understanding the flow of an interface.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.silver.600}",
            "type": "color",
            "description": "Dividers or other elements not critical to understanding the flow of an interface.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-subtle",
          "path": [
            "wh-light",
            "fg",
            "default",
            "subtle"
          ]
        },
        "minimal": {
          "value": "#e9ebea",
          "type": "color",
          "description": "Dividers or other elements not critical to understanding the flow of an interface.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.silver.300}",
            "type": "color",
            "description": "Dividers or other elements not critical to understanding the flow of an interface.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-minimal",
          "path": [
            "wh-light",
            "fg",
            "default",
            "minimal"
          ]
        },
        "inverse": {
          "default": {
            "value": "#fbfbfb",
            "type": "color",
            "description": "Use on bg.primary.",
            "attributes": {
              "category": "color"
            },
            "filePath": "tokens/tokens.json",
            "isSource": true,
            "original": {
              "value": "{core.silver.100}",
              "type": "color",
              "description": "Use on bg.primary.",
              "attributes": {
                "category": "color"
              }
            },
            "name": "fg-inverse",
            "path": [
              "wh-light",
              "fg",
              "default",
              "inverse",
              "default"
            ]
          }
        }
      },
      "primary": {
        "default": {
          "value": "#354A68",
          "type": "color",
          "description": "The most important elements in the interface in which a user must take action on.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.navy.900}",
            "type": "color",
            "description": "The most important elements in the interface in which a user must take action on.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-primary",
          "path": [
            "wh-light",
            "fg",
            "primary",
            "default"
          ]
        },
        "strong": {
          "value": "#092348",
          "type": "color",
          "description": "Selected text, icons and borders.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.navy.1100}",
            "type": "color",
            "description": "Selected text, icons and borders.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-primary-strong",
          "path": [
            "wh-light",
            "fg",
            "primary",
            "strong"
          ]
        },
        "minimal": {
          "value": "#B8BEC7",
          "type": "color",
          "description": "For content that is secondary or that provides additional context. Does not pass contrast standards.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.navy.500}",
            "type": "color",
            "description": "For content that is secondary or that provides additional context. Does not pass contrast standards.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-primary-minimal",
          "path": [
            "wh-light",
            "fg",
            "primary",
            "minimal"
          ]
        }
      },
      "global-black": {
        "value": "#000000",
        "type": "color",
        "description": "When UI element will always be black regardless of theme.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.neutrals.black}",
          "type": "color",
          "description": "When UI element will always be black regardless of theme.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "fg-global-black",
        "path": [
          "wh-light",
          "fg",
          "global-black"
        ]
      },
      "global-white": {
        "value": "#ffffff",
        "type": "color",
        "description": "When UI element will always be white regardless of theme.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.neutrals.white}",
          "type": "color",
          "description": "When UI element will always be white regardless of theme.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "fg-global-white",
        "path": [
          "wh-light",
          "fg",
          "global-white"
        ]
      },
      "focus": {
        "default": {
          "value": "#1957AC",
          "type": "color",
          "description": "The color used to indicate focus on an interactive element, such as a button or input field.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.blue.800}",
            "type": "color",
            "description": "The color used to indicate focus on an interactive element, such as a button or input field.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-focus",
          "path": [
            "wh-light",
            "fg",
            "focus",
            "default"
          ]
        },
        "inverse": {
          "value": "#C9DFFF",
          "type": "color",
          "description": "The inverse color of the focus color, used to indicate focus on elements with a dark background. This color should provide enough contrast with the dark background to be easily visible.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.blue.400}",
            "type": "color",
            "description": "The inverse color of the focus color, used to indicate focus on elements with a dark background. This color should provide enough contrast with the dark background to be easily visible.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-focus-inverse",
          "path": [
            "wh-light",
            "fg",
            "focus",
            "inverse"
          ]
        }
      },
      "text-link": {
        "default": {
          "value": "#2d7794",
          "type": "color",
          "description": "For hyperlinks. This passes contrast standards on the background and surrounding text.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.aqua.700}",
            "type": "color",
            "description": "For hyperlinks. This passes contrast standards on the background and surrounding text.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-text-link",
          "path": [
            "wh-light",
            "fg",
            "text-link",
            "default"
          ]
        }
      }
    },
    "bg": {
      "default": {
        "base": {
          "value": "#f4f5f4",
          "type": "color",
          "description": "The lowest in the background layer order. All bg layers stack on top of this.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.silver.200}",
            "type": "color",
            "description": "The lowest in the background layer order. All bg layers stack on top of this.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "bg-base",
          "path": [
            "wh-light",
            "bg",
            "default",
            "base"
          ]
        },
        "default": {
          "value": "#ffffff",
          "type": "color",
          "description": "Default background color.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.neutrals.white}",
            "type": "color",
            "description": "Default background color.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "bg-default",
          "path": [
            "wh-light",
            "bg",
            "default",
            "default"
          ]
        },
        "layer-one": {
          "value": "#fbfbfb",
          "type": "color",
          "description": "For elements that lay on top of bg.default and lower layers.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.silver.100}",
            "type": "color",
            "description": "For elements that lay on top of bg.default and lower layers.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "bg-layer-one",
          "path": [
            "wh-light",
            "bg",
            "default",
            "layer-one"
          ]
        },
        "layer-two": {
          "value": "#e9ebea",
          "type": "color",
          "description": "For elements that lay on top of bg.default and lower layers.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.silver.300}",
            "type": "color",
            "description": "For elements that lay on top of bg.default and lower layers.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "bg-layer-two",
          "path": [
            "wh-light",
            "bg",
            "default",
            "layer-two"
          ]
        },
        "layer-three": {
          "value": "#dadddc",
          "type": "color",
          "description": "For elements that lay on top of bg.default and lower layers. Check contrast when using.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.silver.400}",
            "type": "color",
            "description": "For elements that lay on top of bg.default and lower layers. Check contrast when using.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "bg-layer-three",
          "path": [
            "wh-light",
            "bg",
            "default",
            "layer-three"
          ]
        }
      },
      "primary": {
        "default": {
          "value": "#0F7F4F",
          "type": "color",
          "description": "The most important elements in the interface in which a user must take action on.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.green.700}",
            "type": "color",
            "description": "The most important elements in the interface in which a user must take action on.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "bg-primary",
          "path": [
            "wh-light",
            "bg",
            "primary",
            "default"
          ]
        },
        "strong": {
          "value": "#001035",
          "type": "color",
          "description": "A focal point of content.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.navy.1200}",
            "type": "color",
            "description": "A focal point of content.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "bg-primary-strong",
          "path": [
            "wh-light",
            "bg",
            "primary",
            "strong"
          ]
        },
        "minimal": {
          "value": "#FAFCFF",
          "type": "color",
          "description": "Content that is secondary or that provides additional context. Needs a border to pass contrast standards.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.blue.100}",
            "type": "color",
            "description": "Content that is secondary or that provides additional context. Needs a border to pass contrast standards.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "bg-primary-minimal",
          "path": [
            "wh-light",
            "bg",
            "primary",
            "minimal"
          ]
        }
      },
      "transparent": {
        "default": {
          "value": "#000000b3",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "#000000b3",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "bg-transparent",
          "path": [
            "wh-light",
            "bg",
            "transparent",
            "default"
          ]
        }
      }
    },
    "highlight": {
      "accent": {
        "default": {
          "value": "#1957AC",
          "type": "color",
          "description": "Indicate changing elements like live scores.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.blue.800}",
            "type": "color",
            "description": "Indicate changing elements like live scores.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "highlight-accent",
          "path": [
            "wh-light",
            "highlight",
            "accent",
            "default"
          ]
        },
        "strong": {
          "value": "#1B3461",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.blue.1000}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "highlight-accent-strong",
          "path": [
            "wh-light",
            "highlight",
            "accent",
            "strong"
          ]
        },
        "subtle": {
          "value": "#E1ECFF",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.blue.300}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "highlight-accent-subtle",
          "path": [
            "wh-light",
            "highlight",
            "accent",
            "subtle"
          ]
        },
        "minimal": {
          "value": "#F0F6FF",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.blue.200}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "highlight-accent-minimal",
          "path": [
            "wh-light",
            "highlight",
            "accent",
            "minimal"
          ]
        }
      },
      "brand": {
        "default": {
          "value": "#2d7794",
          "type": "color",
          "description": "Indicate elements that have a brand flair.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.aqua.700}",
            "type": "color",
            "description": "Indicate elements that have a brand flair.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "highlight-brand",
          "path": [
            "wh-light",
            "highlight",
            "brand",
            "default"
          ]
        }
      },
      "one": {
        "default": {
          "value": "#7E46FF",
          "type": "color",
          "description": "Highlight a non-important UI element. May not pass contrast standards for foreground.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.ultramarine.700}",
            "type": "color",
            "description": "Highlight a non-important UI element. May not pass contrast standards for foreground.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "highlight-one",
          "path": [
            "wh-light",
            "highlight",
            "one",
            "default"
          ]
        }
      },
      "two": {
        "default": {
          "value": "#ee5c00",
          "type": "color",
          "description": "Highlight a non-important UI element. May not pass contrast standards for foreground.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.orange.600}",
            "type": "color",
            "description": "Highlight a non-important UI element. May not pass contrast standards for foreground.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "highlight-two",
          "path": [
            "wh-light",
            "highlight",
            "two",
            "default"
          ]
        }
      },
      "three": {
        "default": {
          "value": "#5ccaf6",
          "type": "color",
          "description": "Highlight a non-important UI element. May not pass contrast standards for foreground.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.aqua.500}",
            "type": "color",
            "description": "Highlight a non-important UI element. May not pass contrast standards for foreground.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "highlight-three",
          "path": [
            "wh-light",
            "highlight",
            "three",
            "default"
          ]
        }
      },
      "four": {
        "default": {
          "value": "#65181d",
          "type": "color",
          "description": "Highlight a non-important UI element. May not pass contrast standards for foreground.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.maroon.1000}",
            "type": "color",
            "description": "Highlight a non-important UI element. May not pass contrast standards for foreground.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "highlight-four",
          "path": [
            "wh-light",
            "highlight",
            "four",
            "default"
          ]
        }
      }
    },
    "status": {
      "positive": {
        "default": {
          "value": "#00663b",
          "type": "color",
          "description": "A positive message, like a placing or winning a bet.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.green.800}",
            "type": "color",
            "description": "A positive message, like a placing or winning a bet.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-positive",
          "path": [
            "wh-light",
            "status",
            "positive",
            "default"
          ]
        },
        "strong": {
          "value": "#0f3c25",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.green.1000}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-positive-strong",
          "path": [
            "wh-light",
            "status",
            "positive",
            "strong"
          ]
        },
        "subtle": {
          "value": "#a0c7a5",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.green.500}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-positive-subtle",
          "path": [
            "wh-light",
            "status",
            "positive",
            "subtle"
          ]
        },
        "minimal": {
          "value": "#f5fdf3",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.green.100}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-positive-minimal",
          "path": [
            "wh-light",
            "status",
            "positive",
            "minimal"
          ]
        }
      },
      "negative": {
        "default": {
          "value": "#B60000",
          "type": "color",
          "description": "A negative message, like an error, blocking status or a loss of a bet.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.red.800}",
            "type": "color",
            "description": "A negative message, like an error, blocking status or a loss of a bet.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-negative",
          "path": [
            "wh-light",
            "status",
            "negative",
            "default"
          ]
        },
        "strong": {
          "value": "#970000",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.red.900}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-negative-strong",
          "path": [
            "wh-light",
            "status",
            "negative",
            "strong"
          ]
        },
        "subtle": {
          "value": "#FD9579",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.red.500}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-negative-subtle",
          "path": [
            "wh-light",
            "status",
            "negative",
            "subtle"
          ]
        },
        "minimal": {
          "value": "#FFFBFA",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.red.100}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-negative-minimal",
          "path": [
            "wh-light",
            "status",
            "negative",
            "minimal"
          ]
        }
      },
      "alert": {
        "default": {
          "value": "#f6b211",
          "type": "color",
          "description": "A standard alert message, indicating a noteworthy event or condition that requires user attention, such as important updates or reminders.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.amber.500}",
            "type": "color",
            "description": "A standard alert message, indicating a noteworthy event or condition that requires user attention, such as important updates or reminders.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-alert",
          "path": [
            "wh-light",
            "status",
            "alert",
            "default"
          ]
        },
        "strong": {
          "value": "#926700",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.amber.700}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-alert-strong",
          "path": [
            "wh-light",
            "status",
            "alert",
            "strong"
          ]
        },
        "subtle": {
          "value": "#ffeaae",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.amber.300}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-alert-subtle",
          "path": [
            "wh-light",
            "status",
            "alert",
            "subtle"
          ]
        },
        "minimal": {
          "value": "#fffbf1",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.amber.100}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-alert-minimal",
          "path": [
            "wh-light",
            "status",
            "alert",
            "minimal"
          ]
        }
      },
      "notification": {
        "value": "#a52a34",
        "type": "color",
        "description": "Use when communicating a non-critical notification in the application interface.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.maroon.800}",
          "type": "color",
          "description": "Use when communicating a non-critical notification in the application interface.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "status-notification",
        "path": [
          "wh-light",
          "status",
          "notification"
        ]
      },
      "informational": {
        "default": {
          "value": "#116DD6",
          "type": "color",
          "description": "A standard informational message, providing additional context or guidance without implying any positive or negative sentiment.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.blue.700}",
            "type": "color",
            "description": "A standard informational message, providing additional context or guidance without implying any positive or negative sentiment.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-informational",
          "path": [
            "wh-light",
            "status",
            "informational",
            "default"
          ]
        },
        "strong": {
          "value": "#1B3461",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.blue.1000}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-informational-strong",
          "path": [
            "wh-light",
            "status",
            "informational",
            "strong"
          ]
        },
        "subtle": {
          "value": "#E1ECFF",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.blue.300}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-informational-subtle",
          "path": [
            "wh-light",
            "status",
            "informational",
            "subtle"
          ]
        },
        "minimal": {
          "value": "#FAFCFF",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.blue.100}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-informational-minimal",
          "path": [
            "wh-light",
            "status",
            "informational",
            "minimal"
          ]
        }
      }
    },
    "rewards": {
      "background": {
        "default": {
          "value": "#a52a34",
          "type": "color",
          "description": "When Caesars Rewards requires a call to action or reference.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.maroon.800}",
            "type": "color",
            "description": "When Caesars Rewards requires a call to action or reference.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "rewards-background",
          "path": [
            "wh-light",
            "rewards",
            "background",
            "default"
          ]
        }
      }
    },
    "spacing": {
      "2xs": {
        "value": "0.25rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.spacing.1}",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-2xs",
        "path": [
          "wh-light",
          "spacing",
          "2xs"
        ]
      },
      "xs": {
        "value": "0.50rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.spacing.2}",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-xs",
        "path": [
          "wh-light",
          "spacing",
          "xs"
        ]
      },
      "sm": {
        "value": "0.75rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.spacing.3}",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-sm",
        "path": [
          "wh-light",
          "spacing",
          "sm"
        ]
      },
      "md": {
        "value": "1rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.spacing.4}",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-md",
        "path": [
          "wh-light",
          "spacing",
          "md"
        ]
      },
      "lg": {
        "value": "1.5rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.spacing.6}",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-lg",
        "path": [
          "wh-light",
          "spacing",
          "lg"
        ]
      },
      "xl": {
        "value": "2rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.spacing.8}",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-xl",
        "path": [
          "wh-light",
          "spacing",
          "xl"
        ]
      },
      "2xl": {
        "value": "4rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.spacing.16}",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-2xl",
        "path": [
          "wh-light",
          "spacing",
          "2xl"
        ]
      }
    },
    "box-shadow": {
      "2xs": {
        "value": {
          "x": "0",
          "y": "0.063rem",
          "blur": "0.063rem",
          "spread": "0",
          "color": "rgba(0,0,0,.10)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.box-shadow.1}",
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-2xs",
        "path": [
          "wh-light",
          "box-shadow",
          "2xs"
        ]
      },
      "xs": {
        "value": {
          "x": "0",
          "y": "0.125rem",
          "blur": "0.125rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.1)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.box-shadow.2}",
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-xs",
        "path": [
          "wh-light",
          "box-shadow",
          "xs"
        ]
      },
      "sm": {
        "value": {
          "x": "0",
          "y": "0.25rem",
          "blur": "0.25rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.1)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.box-shadow.4}",
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-sm",
        "path": [
          "wh-light",
          "box-shadow",
          "sm"
        ]
      },
      "md": {
        "value": {
          "x": "0",
          "y": "0.5rem",
          "blur": "0.5rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.08)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.box-shadow.8}",
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-md",
        "path": [
          "wh-light",
          "box-shadow",
          "md"
        ]
      },
      "lg": {
        "value": {
          "x": "0",
          "y": "0.75rem",
          "blur": "0.75rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.07)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.box-shadow.12}",
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-lg",
        "path": [
          "wh-light",
          "box-shadow",
          "lg"
        ]
      },
      "xl": {
        "value": {
          "x": "0",
          "y": "1.125rem",
          "blur": "1.125rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.06)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.box-shadow.18}",
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-xl",
        "path": [
          "wh-light",
          "box-shadow",
          "xl"
        ]
      },
      "2xl": {
        "value": {
          "x": "0",
          "y": "1.5rem",
          "blur": "1.5rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.05)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.box-shadow.24}",
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-2xl",
        "path": [
          "wh-light",
          "box-shadow",
          "2xl"
        ]
      }
    },
    "border-radius": {
      "sm": {
        "value": "0.25rem",
        "type": "borderRadius",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.border-radius.1}",
          "type": "borderRadius",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-radius-sm",
        "path": [
          "wh-light",
          "border-radius",
          "sm"
        ]
      },
      "md": {
        "value": "0.5rem",
        "type": "borderRadius",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.border-radius.2}",
          "type": "borderRadius",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-radius-md",
        "path": [
          "wh-light",
          "border-radius",
          "md"
        ]
      },
      "lg": {
        "value": "1.25rem",
        "type": "borderRadius",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.border-radius.5}",
          "type": "borderRadius",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-radius-lg",
        "path": [
          "wh-light",
          "border-radius",
          "lg"
        ]
      }
    },
    "font-family": {
      "primary": {
        "value": "Roboto Flex, Roboto Condensed",
        "type": "fontFamilies",
        "attributes": {
          "category": "content"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.font-family.roboto-flex}, {core.font-family.roboto-condensed}",
          "type": "fontFamilies",
          "attributes": {
            "category": "content"
          }
        },
        "name": "font-family-primary",
        "path": [
          "wh-light",
          "font-family",
          "primary"
        ]
      },
      "secondary": {
        "value": "oswald, Oswald Static",
        "type": "fontFamilies",
        "attributes": {
          "category": "content"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.font-family.oswald}, {core.font-family.oswald-static}",
          "type": "fontFamilies",
          "attributes": {
            "category": "content"
          }
        },
        "name": "font-family-secondary",
        "path": [
          "wh-light",
          "font-family",
          "secondary"
        ]
      }
    }
  }
};